import styled from "styled-components";

export const ControlTextSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

export const ControlRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const CardWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.globalShadow};
  align-items: stretch;
  justify-items: first baseline;
  justify-content: space-around;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

export const ControlColumn = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 125px;
  align-content: flex-start;
  padding: 1.25rem;
  transition: all 0.3s ease;

  @media (max-width: 500px) {
    width: 218px;
  }
`;

export const CardTitle = styled.div`
  text-align: center;
  width: 100%;
  margin: 1rem 0;
`;

export const ButtonUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem;
  position: relative;
  overflow: hidden;
`;

export const ButtonUpBackColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonUpFace = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  transform: ${(props) => (props.isFlipped ? `rotateX(180deg)` : " ")};
`;

export const ButtonUpFront = styled(CardFace)`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
