import styled from "styled-components";

const FontPickerControl = styled.div`
  #font-picker {
    background: none;
    box-shadow: none;

    .dropdown-button {
      background: none;
      border-bottom: 2px solid ${(props) => props.theme.color.gray};
      font-size: ${(props) => props.theme.label};
      font-weight: ${(props) => props.theme.weight[1]};
      color: ${(props) => props.theme.color.gray};

      &:hover {
        border-color: ${(props) => props.theme.color.brand};
      }
    }

    .dropdown-icon.finished::before {
      border-top: 6px solid ${(props) => props.theme.color.brand};
    }

    .font-list {
      background: ${(props) => props.theme.color.grayLight};
      box-shadow: none;
    }

    .font-button {
      color: ${(props) => props.theme.color.grayDark};
    }
  }
`;

export default FontPickerControl;
