import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import PropTypes from 'prop-types';

const SliderContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const StyledSlider = styled(ReactSlider)`
    width: 100px;
    height: 26px;
    align-items: center;
    display: flex;
`;

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: ${(props) => props.theme.color.grayLight};
    color: ${(props) => props.theme.color.gray};
    border-radius: 50%;
    cursor: grab;
`;

const Thumb = (props, state) => {
    return <StyledThumb {...props}>{state.valueNow}</StyledThumb>;
};

const StyledTrack = styled.div`
    width: auto;
    top: 9px;
    bottom: 0;
    height: 9px;
    background: ${(props) =>
        props.index ? props.theme.color.grayLight : props.theme.color.brand};
    border-radius: 999px;
`;

const CounterButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.color.brand};
    margin: 0 1rem;
`;

const Track = (props, state) => {
    return <StyledTrack {...props} index={state.index} />;
};

function TextSizeControl(props) {
    return (
        <SliderContainer>
            <CounterButton
                onClick={() =>
                    props.minusFontSize(
                        props.fontSizeValue - 1,
                        props.valueNow - 1
                    )
                }
            >
                -
            </CounterButton>
            <StyledSlider
                defaultValue={props.defaultValue}
                max={props.max}
                onAfterChange={props.changeFontSize}
                fontSize={props.index}
                renderTrack={Track}
                renderThumb={Thumb}
            ></StyledSlider>
            <CounterButton
                onClick={() => props.plusFontSize(props.fontSizeValue + 1)}
            >
                +
            </CounterButton>
        </SliderContainer>
    );
}

TextSizeControl.propTypes = {
    minusFontSize: PropTypes.func,
    fontSizeValue: PropTypes.string,
    fontSize: PropTypes.number,
    valueNow: PropTypes.number,
    defaultValue: PropTypes.array,
    max: PropTypes.number,
    changeFontSize: PropTypes.func,
    value: PropTypes.number,
    plusFontSize: PropTypes.func,
};

export default TextSizeControl;
