import styled from "styled-components";

export const Main = styled.main`
  position: relative;
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1200px;
  overflow: scroll;
`;

export const Header = styled.header`
  grid-column: 1 / span 14;
  grid-row: 1;
`;

export const ButtonUpContainer = styled.section`
  grid-row: 2;
  margin: 3rem 0 0 0;
  border: ${(props) =>
    props.isFlipped ? "0px dashed #50E3C2" : "2px dashed #50E3C2"};
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 50vh;
  width: 100%;
  perspective: 5000px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 1470px) {
    grid-column: col-start 7 / span 6;
  }

  @media (max-width: 500px) {
    grid-column: col-start 2 / span 10;
  }
`;

export const Body = styled.main`
  display: flex;
  justify-content: center;

  @media (min-width: 500px) {
  }
`;

export const Controls = styled.section`
  position: relative;
  align-content: center;
  justify-content: center;

  @media (max-width: 500px) {
    height: 100%;
  }
`;

export const Footer = styled.footer`
  background: ${(props) => props.theme.color.grayDark};
`;

export const FooterContainer = styled.div`
  margin: 100px 0;
`;
