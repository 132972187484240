import React, { Fragment, useState } from 'react';
import { ReactComponent as Arrow } from '../graphics/arrowup.svg';
import styled from 'styled-components';

const IconButton = () => (
    <Fragment>
        <Arrow />
    </Fragment>
);

const TwistButtonStyle = styled.button`
    outline: none;
    cursor: pointer;
    padding: 30px;
    background: ${(props) => props.theme.color.brand};
    box-shadow: ${(props) => props.theme.globalShadow};
    border: none;
    border-radius: 20px;
    position: absolute;
    top: -45px;
    z-index: 999999;
    left: -45px;
    transition: transform 0.5s ease-in-out;
    transform: ${(props) => (props.isFlipped ? 'scale(0)' : 'scale(1)')};

    @media (max-width: 500px) {
        padding: 20px;
        left: -35px;
    }
`;

const IconButtonWrapper = styled.div`
    transform: rotate(180deg);
    overflow: hidden;
    transition: all 0.7s ease-out;
    transform: ${(props) =>
        props.spin ? `rotate(900deg)` : `rotate(-900deg)`};
`;

const IconButtonContainer = (props) => {
    const [spin, setSpin] = useState(false);

    return (
        // OnClick is passed to TwistButtonWrapper
        <TwistButtonStyle
            isFlipped={props.isFlipped}
            onClick={() => setSpin(!spin)}
            spin={spin}
        >
            <IconButtonWrapper spin={spin}>
                <IconButton />
            </IconButtonWrapper>
        </TwistButtonStyle>
    );
};

const TwistButtonContainer = styled.div`
    position: absolute;
`;

const TwistButtonWrapper = (props) => {
    return (
        // OnClick is passed from to IconButtonContainer by props
        <Fragment>
            <TwistButtonContainer onClick={props.onClick}>
                <IconButtonContainer
                    isFlipped={props.isFlipped}
                    onClick={props.onClick}
                />
            </TwistButtonContainer>
        </Fragment>
    );
};

export default TwistButtonWrapper;
