import { useState, useCallback } from "react";
// The history is all committed states (everything except the final entry).
function getHistory(states) {
  return states.slice(0, states.length - 1);
}
// The staged state is the final entry in the history.
function getStagedState(states) {
  return states[states.length - 1];
}
// Our useStagedHistory hook receices a type `StateType` that describes
// what state we want to store.
//
// It allows us to keep track of the current state, and a history of "committed" states.
export default function useStagedHistory(initialState) {
  // First, we create a React state to store our history in chronological order
  // as an array of `StateType`. Each entry is a snapshot of the state at a particular time.
  //
  // The final entry in the array is considered the "staged state", it is not considered
  // part of the history until it has been committed.
  const [states, setStates] = useState([initialState]);
  // The commitState function commits the current state to history.
  // It does this by copying the staged state (the final entry), and appending it.
  const commitState = useCallback(() => {
    setStates((states) => {
      return [...states, getStagedState(states)];
    });
  }, [setStates]);
  // The setState function allows us to update the staged state, which is the final item
  // in the history.
  const setState = useCallback((value) => {
    setStates((states) => {
      return [...getHistory(states), value(getStagedState(states))];
    });
  }, []);
  // Reset the history.
  const resetHistory = useCallback(() => setStates([initialState]), [
    setStates,
    initialState,
  ]);
  return {
    state: getStagedState(states),
    history: getHistory(states),
    setState,
    commitState,
    resetHistory,
  };
}
