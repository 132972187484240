import React, { Fragment, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../globals/theme";

function ButtonUp(props) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  //console.log(hovered)
  //console.log("button-up comp:", props.state);
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <ButtonStyle
          hovered={hovered}
          state={props.state}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {props.buttonText}
        </ButtonStyle>
      </ThemeProvider>
    </Fragment>
  );
}

export const ButtonStyle = styled.button`
  background-color: ${(props) =>
    props.state
      ? props.state.backgroundColor
      : props.theme.style.backgroundColor};
  color: ${(props) =>
    props.state ? props.state.color : props.theme.style.color};
  border-color: ${(props) =>
    props.state ? props.state.borderColor : props.theme.style.borderColor};
  cursor: ${(props) =>
    props.state ? props.state.pointer : props.theme.style.pointer};
  outline: none;
  position: relative;
  border-radius: ${(props) =>
    props.state ? props.state.borderRadius : props.theme.style.borderRadius};
  border: ${(props) =>
    props.state ? props.state.border : props.theme.style.border};
  border-style: ${(props) =>
    props.state ? props.state.borderStyle : props.theme.style.borderStyle};
  padding: ${(props) =>
    props.state ? props.state.padding : props.theme.style.padding};
  box-shadow: ${(props) =>
    props.state ? props.state.boxShadow : props.theme.style.boxShadow};
  text-transform: ${(props) =>
    props.state ? props.state.transform : props.theme.style.transform};
  letter-spacing: ${(props) =>
    props.state ? props.state.spacing : props.theme.style.spacing};
  font-weight: ${(props) =>
    props.state ? props.state.fontWeight : props.theme.style.fontWeight};
  font-family: ${(props) =>
    props.state
      ? props.state.activeFontFamily
      : props.theme.style.activeFontFamily};
  font-size: ${(props) =>
    props.state ? props.state.fontSize : props.theme.style.fontSize};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.state
        ? props.state.hover.backgroundColor
        : props.theme.style.hover.backgroundColor} !important;
    color: ${(props) =>
      props.state
        ? props.state.hover.color
        : props.theme.style.hover.color} !important;
    border-color: ${(props) =>
      props.state
        ? props.state.hover.borderColor
        : props.theme.style.hover.borderColor} !important;
  }
`;

export default ButtonUp;
