import React, { Fragment } from "react";
import BorderColourPicker from "../controls/BorderColourPicker";
import BgColourPicker from "../controls/BgColourPicker";
import PropTypes from "prop-types";
import { Column, Row } from "../globals/grid";
import { Label } from "../typography/Typography";
import TextColourPicker from "../controls/TextColourPicker";

function ControlColors(props) {
  //console.log(props)

  return (
    <Fragment>
      <Row justifyContent="space-between" width="100%">
        <Column justifyContent="center" textAlign="center" alignItems="center">
          <Label>Border Colour</Label>
          <BorderColourPicker
            changeBorderColour={props.changeBorderColour}
            borderColor={props.borderColor}
          />
        </Column>
        <Column justifyContent="center" textAlign="center" alignItems="center">
          <Label>Background Colour</Label>
          <BgColourPicker
            changeBackgroundColor={props.changeBackgroundColor}
            backgroundColor={props.backgroundColor}
          />
        </Column>
        <Column justifyContent="center" textAlign="center" alignItems="center">
          <Label>Text Colour</Label>
          <TextColourPicker
            changeTextColour={props.changeTextColour}
            textColor={props.textColor}
          />
        </Column>
      </Row>
    </Fragment>
  );
}

ControlColors.propTypes = {
  changeBorderRadius: PropTypes.func,
  changeBorderColour: PropTypes.func,
  changeBackgroundColor: PropTypes.func,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  value: PropTypes.number,
};

export default ControlColors;
