import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";

const StyledSlider = styled(ReactSlider)`
  width: 75px;
  height: 25px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: ${(props) => props.theme.color.grayLight};
  color: ${(props) => props.theme.color.gray};
  border-radius: 50%;
  cursor: grab;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const StyledTrack = styled.div`
  top: 10px;
  bottom: 0;
  height: 10px;
  background: ${(props) =>
    props.index ? props.theme.color.grayLight : props.theme.color.brand};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

function BorderRadiusSize(props) {
  //console.log(props)
  return (
    <StyledSlider
      defaultValue={props.defaultValue}
      max={props.max}
      onAfterChange={props.changeBorderRadius}
      fontSize={props.index}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  );
}

export default BorderRadiusSize;
