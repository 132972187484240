import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from './logo.svg';

const Nav = styled.div`
  background-color: #F2F2F3;
`;
const NavHeader = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`;
const NavLeft = styled.div`
  width: 33.333%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const NavCenter = styled.div`
  width: 33.333%;
  text-align: center;
`;

const NavRight = styled.div`
  width: 33.333%;
  text-align: right;
  svg {
    margin-right: 20px;
  }
`;
const MenuLink = styled.a``;

function Navigation() {
  return (
    <Nav>
      <NavHeader>
        <NavLeft>
          <Logo />
        </NavLeft>
        <NavCenter>
          <MenuLink href="#">
           
          </MenuLink>
        </NavCenter>
        <NavRight>
          <MenuLink href="#">
          </MenuLink>
          <MenuLink href="#">

          </MenuLink>
          <MenuLink href="#">
            
          </MenuLink>
        </NavRight>
      </NavHeader>
    </Nav>
  );
}
export default Navigation;