import styled from "styled-components";
import { theme } from "../globals/theme";

const TabButton = styled.button`
  cursor: pointer;
  background: ${theme.color.grayLight};
  outline: none;
  padding: 0.5rem 0;
  border: none;
  width: 100%;
  border-right: 1px solid ${theme.color.grayMid};
  transition: 0.2s linear;

  &:hover,
  &.focus {
    background: ${theme.color.brandLight};
  }
`;

const TabView = styled.div`
  padding: 2rem;
`;

const TabMenu = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { TabButton, TabView, TabMenu };
