import React from "react";
import styled, { css } from "styled-components";
import { device, box } from "./theme";
import { easing } from "./animations";

export const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${box.global};
  transition: ${easing.theme};
`;

export const SmallStack = styled.div`
  & > * + * {
    margin-top: 1.5rem;
  }
`;

export const InnerStack = styled.div`
  & > * + * {
    margin-top: 1.5rem;
  }

  @media ${device.mobileL} {
    & > * + * {
      margin-top: 3rem;
    }
  }
`;

/**
 * Grid Row
 */

export const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  flex-flow: ${(props) => props.direction};
  display: ${(props) => props.display};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  justify-content: ${(props) => props.justifyContent};
  justify-items: ${(props) => props.justifyItems};
`;

const withRow = (Component) => styled(GridRow)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const Row = withRow(({ className, children }) =>
  React.cloneElement(children, { className })
);

/**
 * Grid Column
 */

export const Col = styled.div`
  position: ${(props) => (props.position ? props.position : "relative")};
  height: ${(props) => props.height};
  flex-flow: ${(props) => (props.direction ? props.direction : "column")};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  justify-content: ${(props) => props.justifyContent};
  justify-items: ${(props) => props.justifyItems};
  text-align: ${(props) => props.textAlign};

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.fullHeight &&
    css`
      height: auto;

      @media ${device.mobileL} {
        height: 100%;
      }
    `}

  ${(props) =>
    props.fullHWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.innerStack &&
    css`
      & > * + * {
        margin-top: 1.5rem;
      }
    `}

    ${(props) =>
    props.fixed &&
    css`
      position: relative;

      @media ${device.mobileL} {
        position: fixed;
      }
    `}

  ${(props) =>
    props.gutters &&
    css`
      padding-right: 0;
      padding-left: 0;

      @media ${device.mobileL} {
        padding-right: ${box.halfGutterWidth};
        padding-left: ${box.halfGutterWidth};
      }
    `}
`;

const withColumn = (Component) => styled(Col)`
  width: ${(props) => props.xsWidth};
  padding-top: ${(props) => props.xsPaddingTop};
  transition: ${easing.theme};

  @media ${device.mobileL} {
    width: ${(props) => props.smWidth};
    margin-left: ${(props) => props.smOffsetWidth};
    padding-top: ${(props) => props.smPaddingTop};
  }

  @media ${device.tablet} {
    width: ${(props) => props.mdWidth};
    margin-left: ${(props) => props.mdOffsetWidth};
    padding-top: ${(props) => props.mdPaddingTop};
  }

  @media ${device.laptop} {
    width: ${(props) => props.lgWidth};
    margin-left: ${(props) => props.lgOffsetWidth};
    padding-top: ${(props) => props.lgPaddingTop};
  }
`;

export const Column = withColumn(({ className, children }) =>
  React.cloneElement(children, { className })
);
