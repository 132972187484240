import styled from "styled-components";
import { theme } from "../globals/theme";

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.heading[0]};
  font-family: "Quicksand", sans-serif;
  font-weight: ${(props) => props.theme.weight[1]};
  margin: 40px 0;
  line-height: 56px;
  text-align: center;
  color: ${(props) => props.theme.color.grayDark};
`;

export const H2 = styled.h2`
  font-size: calc(40px + 2vmin);
  font-family: "Quicksand", sans-serif;
  font-weight: ${(props) => props.theme.fontStyles[1]};
  margin: 90px 0;
  line-height: 56px;
  color: ${(props) => props.theme.color.grayDark};
`;

export const H3 = styled.h3`
  font-size: calc(40px + 2vmin);
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  line-height: 56px;
  color: ${(props) => props.theme.color.grayDark};
`;

export const Label = styled.p`
  font-size: ${theme.label.small};
  font-family: "Quicksand", sans-serif;
  font-weight: ${(props) => props.theme.weight[2]};
  text-align: center;
  color: ${theme.color.gray};
  padding-bottom: 10px;
`;

export const LabelLeft = styled(Label)`
  text-align: left;
`;

export const LabelRight = styled(Label)`
  text-align: right;
`;
