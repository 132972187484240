import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ButtonHTML = (props) => {
    return (
        <HTMLCode>
            <HTMLIndent>
                <CodeForeground>{'<'}</CodeForeground>
                <CodeRed>{'a href="#" '}</CodeRed>
                <CodeGreen>{'class'}</CodeGreen>
                <CodeForeground>{'='}</CodeForeground>
                <CodeYellow>{'"button-up"'}</CodeYellow>
                <CodeForeground>{'>'}</CodeForeground>
            </HTMLIndent>
            <HTMLIndent2>
                <CodeForeground>{props.text}</CodeForeground>
            </HTMLIndent2>
            <HTMLIndent>
                <CodeForeground>{'</'}</CodeForeground>
                <CodeRed>{'button'}</CodeRed>
                <CodeForeground>{'>'}</CodeForeground>
            </HTMLIndent>
        </HTMLCode>
    );
};

ButtonHTML.propTypes = {
    text: PropTypes.string,
};

export const ButtonCSS = (props) => {
    const raw = props.style;
    const allowed = [
        'pointer',
        'backgroundColor',
        'borderColor',
        'color',
        'borderRadius',
        'padding',
        'borderStyle',
        'transform',
        'letterSpacing',
        'fontWeight',
        'fontStyle',
        'boxShadow',
        'fontFamily',
        'fontSize',
    ];

    // filter style object to allow above keys
    const filteredStyle = Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
        }, {});

    let styleCode = [];

    Object.keys(filteredStyle).forEach(function (key) {
        let keyToCase = key.replace(
            /([A-Z])/g,
            (g) => `-${g[0].toLowerCase()}`
        );
        //let cssValue = Object.keys(filteredStyle).replace(/(px|rem|em|%)/g, (m) => <CodePurple> `${m}` </CodePurple>);

        styleCode.push(
            <Fragment key={key}>
                <CSSstyle>
                    <CodeBlue value={key}>
                        {keyToCase}
                        <CodePurple>: </CodePurple>
                    </CodeBlue>

                    <CodePurple>
                        {filteredStyle[key]}
                        <CodeForeground>;</CodeForeground>
                    </CodePurple>
                </CSSstyle>
            </Fragment>
        );
    });

    return (
        <Fragment>
            <CSScode>
                <CodeGreen>
                    {'.button-up '}
                    <CodeForeground>{'{ '}</CodeForeground>
                </CodeGreen>
                {styleCode}
                <CodeForeground>{'}'}</CodeForeground>
            </CSScode>
        </Fragment>
    );
};

ButtonCSS.propTypes = {
    style: PropTypes.object,
};

export const ButtonCSSHover = (props) => {
    const raw = props.style.hover;
    const allowed = ['backgroundColor', 'borderColor', 'color'];

    // filter style object to allow above keys
    const filteredStyle = Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
        }, {});

    let styleCode = [];

    Object.keys(filteredStyle).forEach(function (key) {
        let keyToCase = key.replace(
            /([A-Z])/g,
            (g) => `-${g[0].toLowerCase()}`
        );
        //let cssValue = Object.keys(filteredStyle).replace(/(px|rem|em|%)/g, (m) => <CodePurple> `${m}` </CodePurple>);

        styleCode.push(
            <Fragment key={key}>
                <CSSstyle>
                    <CodeBlue value={key}>
                        {keyToCase}
                        <CodePurple>: </CodePurple>
                    </CodeBlue>

                    <CodePurple>
                        {filteredStyle[key]}
                        <CodeForeground>;</CodeForeground>
                    </CodePurple>
                </CSSstyle>
            </Fragment>
        );
    });

    return (
        <Fragment>
            <CSScode>
                <CodeGreen>
                    {'.button-up:hover '}
                    <CodeForeground>{'{ '}</CodeForeground>
                </CodeGreen>
                {styleCode}
                <CodeForeground>{'}'}</CodeForeground>
            </CSScode>
        </Fragment>
    );
};

ButtonCSS.propTypes = {
    style: PropTypes.object,
};

export const ButtonUpBack = styled.pre`
    cursor: text;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${(props) => props.theme.color.grayDark};
    border: solide 5px black;
    box-shadow: ${(props) => props.theme.globalShadow};
    transform: rotateX(-180deg);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
`;

export const ButtonUpBackContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    overflow: scroll;

    @media (max-width: 500px) {
        flex-direction: column;
        overflow: scroll;
    }
`;

// Markdown styles
export const Pre = styled.pre`
    color: ${(props) => props.theme.color.white};
`;

export const CodeBackground = styled.span`
    color: ${(props) => props.theme.monokai.background};
`;

const CodeForeground = styled.span`
    color: ${(props) => props.theme.monokai.foreground};
`;

export const HTMLIndent = styled.div`
    color: ${(props) => props.theme.monokai.foreground};
`;

export const HTMLIndent2 = styled(HTMLIndent)`
    padding: 0 2rem;
`;

export const CodeComment = styled.span`
    color: ${(props) => props.theme.monokai.comment};
`;

export const CodeRed = styled.span`
    color: ${(props) => props.theme.monokai.red};
`;

export const CodeOrange = styled.span`
    color: ${(props) => props.theme.monokai.orange};
`;

export const CodeLightOrange = styled.span`
    color: ${(props) => props.theme.monokai.lightOrange};
`;

export const CodeYellow = styled.span`
    color: ${(props) => props.theme.monokai.yellow};
`;

export const CodeGreen = styled.span`
    color: ${(props) => props.theme.monokai.green};
`;

export const CodeBlue = styled.span`
    color: ${(props) => props.theme.monokai.blue};
`;

export const CodePurple = styled.span`
    color: ${(props) => props.theme.monokai.purple};
`;

export const CSSstyle = styled.li`
    text-indent: 20px;
    list-style: none;
`;

const Code = styled.code`
    font-size: 14px;

    @media (max-width: 500px) {
        font-size: 0.75em;
        padding: 0 1rem;
    }
`;

export const CSScode = styled(Code)`
    display: flex;
    flex-direction: column;
`;

export const HTMLCode = styled(Code)``;
