import React, { Fragment, useState } from "react";
import { CompactPicker } from "react-color";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../globals/theme";

function BgColourPicker(props) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleOpen = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const handleClose = () => {
    setIsPickerOpen(false);
  };

  // console.log(isPickerOpen);
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Swatch onClick={handleOpen}>
          <Color color={props.backgroundColor} />
        </Swatch>
        <PopOver>
          <Cover onClick={handleClose} isPickerOpen={isPickerOpen} />
          <PickerContainer isPickerOpen={isPickerOpen}>
            <CompactPicker
              onChangeComplete={props.changeBackgroundColor}
              color={props.backgroundColor}
            />
          </PickerContainer>
        </PopOver>
      </ThemeProvider>
    </Fragment>
  );
}

const Color = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: ${(props) => props.color};
`;

const Swatch = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 1;
`;

const PopOver = styled.div`
  position: relative;
  z-index: 2;
`;

const Cover = styled.div`
  position: ${(props) => (props.isPickerOpen ? "fixed" : "absolute")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PickerContainer = styled.div`
  position: absolute;
  left: -50%;
  bottom: 0;
  transition: all 0.25s ease-in-out;
  transform: ${(props) =>
    props.isPickerOpen
      ? "translatey(0) scale(1)"
      : "translatey(100%) scale(0)"};
  opacity: ${(props) => (props.isPickerOpen ? "1" : "0")};

  @media (max-width: 500px) {
    top: 0;
    transform: ${(props) =>
      props.isPickerOpen ? "translate(-13px, 10px);" : "translatex(0)"};
  }
`;

export default BgColourPicker;
