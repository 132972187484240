import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../globals/theme";
import {
  RevealContainer,
  RevealCodeButton,
  RevealHistoryButton,
} from "./style";

function RevealSection(props) {
  //console.log(props)
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <RevealContainer>
          <RevealHistoryButton
            onClick={() => props.setIsHidden(!props.isHidden)}
          >
            {props.isHidden ? "Close History" : "Show History"}
          </RevealHistoryButton>
          <RevealCodeButton
            onClick={() => props.setIsFlipped(!props.isFlipped)}
          >
            {props.isFlipped ? "Button Up More!" : "Get Code"}
          </RevealCodeButton>
        </RevealContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export default RevealSection;
