import React from "react";
import PropTypes from "prop-types";
import { TabButton, TabMenu, TabView } from "./style";
import { Label } from "../typography/Typography";

const TabItem = (props) => {
  return <div {...props} />;
};

const Tabs = ({ defaultIndex = 0, onTabClick, children, ...props }) => {
  const [bindIndex, setBindIndex] = React.useState(defaultIndex);
  const changeTab = (newIndex) => {
    if (typeof onTabClick === "function") onTabClick(newIndex);
    setBindIndex(newIndex);
  };

  return (
    <div className="wrapper">
      <TabMenu>
        {children.map(({ props: { index, label } }) => (
          <TabButton
            key={index}
            onClick={() => changeTab(index)}
            className={bindIndex === index ? "focus" : ""}
          >
            <Label>{label}</Label>
          </TabButton>
        ))}
      </TabMenu>
      <TabView>
        {children.map(({ props }) => (
          <div
            {...props}
            className="tab-view_item"
            key={props.index}
            style={{ display: bindIndex === props.index ? "block" : "none" }}
          />
        ))}
      </TabView>
    </div>
  );
};

Tabs.propTypes = {
  defaultIndex: PropTypes.string,
  onTabClick: PropTypes.func,
  children: PropTypes.any,
  index: PropTypes.any,
};

export { TabItem, Tabs };
