import React, { Fragment } from 'react';
import FontPickerControl from '../controls/FontPickerControl';
import FontPicker from 'font-picker-react';
import TextSizeControl from '../controls/TextSizeControl';
import { InputContainer, InputField } from '../controls/InputCheckMark';
import PropTypes from 'prop-types';
import { Column, Row } from '../globals/grid';

function ControlTextOptions(props) {
    return (
        <Fragment>
            <Row justifyContent="space-between" width="100%">
                <Column
                    flex
                    justifyContent="center"
                    textAlign="center"
                    alignItems="flex-start"
                >
                    <FontPickerControl>
                        <FontPicker
                            apiKey="AIzaSyCudwcvLlnFb1P_C6cY6iXcEVH57B08HM4"
                            activeFontFamily={
                                props.state
                                    ? props.state.fontFamily
                                    : props.activeFontFamily
                            }
                            onChange={(nextFont) =>
                                props.handleUpdateStyle({
                                    activeFontFamily: nextFont.family,
                                    fontFamily: nextFont.family,
                                })
                            }
                        />
                    </FontPickerControl>
                </Column>
                <Column
                    flex
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <TextSizeControl
                        defaultValue={[18]}
                        max={75}
                        changeFontSize={props.changeFontSize}
                        fontSize={props.fontSize}
                        minusFontSize={props.minusFontSize}
                        fontSizeValue={props.fontSizeValue}
                        plusFontSize={props.plusFontSize}
                    />
                </Column>
                <Column
                    flex
                    justifyContent="flex-start"
                    textAlign="left"
                    alignItems="flex-start"
                >
                    <InputContainer>
                        <InputField
                            text={props.text}
                            type="text"
                            value={props.text}
                            onChange={props.handleTextInput}
                        />
                    </InputContainer>
                </Column>
            </Row>
        </Fragment>
    );
}

ControlTextOptions.propTypes = {
    defaultValue: PropTypes.array,
    isOpenText: PropTypes.bool,
    active: PropTypes.bool,
    fontSizeValue: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.object,
    activeFontFamily: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,
    valueNow: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    changeFontSize: PropTypes.func,
    changeFontWeight: PropTypes.func,
    changeTextColour: PropTypes.func,
    handleTextInput: PropTypes.func,
    handleUpdateStyle: PropTypes.func,
    handleUpdateText: PropTypes.func,
    minusFontSize: PropTypes.func,
    plusFontSize: PropTypes.func,
};

export default ControlTextOptions;
