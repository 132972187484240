import React, { Fragment } from "react";
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
} from "../controls/ShadowControls";
import PropTypes from "prop-types";
import { Column, Row } from "../globals/grid";
import { Label } from "../typography/Typography";
import BorderRadiusSize from "../controls/BorderRadiusSize";

function ControlAppearance(props) {
  //console.log(props);
  return (
    <Fragment>
      <Row justifyContent="space-between" width="100%">
        <Column justifyContent="center" textAlign="center" alignItems="center">
          <Label>Shadow</Label>
          <CheckBoxWrapper shadow={props.currentShadow}>
            <CheckBox
              id="checkbox"
              type="checkbox"
              onClick={props.toggleBoxShadow}
            />
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper>
        </Column>
        <Column justifyContent="center" textAlign="center" alignItems="center">
          <Label>Border Radius</Label>
          <BorderRadiusSize
            defaultValue={[0]}
            max={75}
            changeBorderRadius={props.changeBorderRadius}
            fontSize={props.value}
          />
        </Column>
      </Row>
    </Fragment>
  );
}

ControlAppearance.propTypes = {
  openShadow: PropTypes.bool,
};

export default ControlAppearance;
