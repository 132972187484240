import styled from 'styled-components';
import { theme } from '../globals/theme';

export const HistoryGrid = styled.ul`
    display: flex;
    position: relative;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(0.75 * 20px);
    margin-bottom: calc(-0.5 * 20px);
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;

    @media (max-width: 500px) {
        grid-template-columns: 10px repeat(50, calc(75% - 0px * 2)) 10px;
    }
`;

export const HistoryContainer = styled.div`
    position: relative;
    grid-column: col-start 2 / span 10;
    grid-row: 4;
    border: 2px dashed ${theme.color.greyDark};
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    transform: ${(props) => (props.isHidden ? 'scale(1)' : 'scale(0)')};
    height: ${(props) => (props.isHidden ? '200px' : '0')};
    margin-bottom: ${(props) => (props.isHidden ? '2rem' : '0')};

    @media (max-width: 500px) {
        grid-column: col-start 2 / span 10;
    }

    .hide-scroll {
        overflow-y: hidden;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100px;
        z-index: 99999;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 1) 100%
        );
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100px;
        z-index: 99999;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 1) 100%
        );
    }
`;

export const IconArrow = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    align-self: center;
    opacity: 0;
    top: 20%;
    position: absolute;
    z-index: 9;
    transition: all 0.5s ease-in-out;
`;

export const HistoryButtonItem = styled.li`
    transition: all 0.75s ease-in-out;
    margin: 0 2rem;

    &:hover > ${IconArrow} {
        opacity: 1;
        top: 0%;
    }
`;

export const RevealCodeButton = styled.div`
    cursor: pointer;
    border: none;
    background: none;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.color.brand};
`;

export const RevealContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    transition: all 2.75s ease-in-out;

    @media (max-width: 500px) {
        grid-column: col-start 2 / span 10;
    }
`;

export const RevealHistoryButton = styled.button`
    cursor: pointer;
    border: none;
    background: none;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.color.brand};
`;
