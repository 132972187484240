// The theme is the single point for all the updating states of the button
export const theme = {
    // Colours
    color: {
        brand: '#50E3C2',
        brandLight: '#96EEDA',
        brandDark: '#256859',
        blue: '#66D9EF',
        danger: '#E2363F',
        warning: '#EDB95E',
        success: '#81EE7F',
        grayDark: '#415058',
        gray: '#A5ABB0',
        grayMid: '#C8CDD0',
        grayLight: '#F2F2F3',
        black: '#F2F2F3',
        white: '#fff',
    },

    monokai: {
        background: '#272822',
        foreground: '#F8F8F2',
        comment: '#75715E',
        red: '#F92672',
        orange: '#FD971F',
        lightOrange: '#E69F66',
        yellow: '#E6DB74',
        green: '#A6E22E',
        blue: '#66D9EF',
        purple: '#AE81FF',
    },

    fontFamily: "'Quicksand', sans-serif",
    fontFamilies: [
        'Arimo',
        'Barlow',
        'Crimson Text',
        'Dosis',
        'Fira Sans',
        'Heebo',
        'Hind Siliguri',
        'Inconsolata',
        'Josefin Sans',
        'Karla',
        'Lato',
        'Libre Baskerville',
        'Libre Franklin',
        'Lora',
        'Merriweather',
        'Montserrat',
        'Inconsolata',
        'Mukta',
        'Nanum Gothic',
        'Noto Sans',
        'Noto Sans JP',
        'Noto Sans KR',
        'Noto Sans TC',
        'Noto Serif',
        'Nunito Sans',
        'Open Sans',
        'Oswald',
        'Playfair Display',
        'Poppins',
        'PT Sans',
        'PT Sans Narrow',
        'PT Serif',
        'Quicksand',
        'Raleway',
        'Roboto',
        'Roboto Condensed',
        'Roboto Mono',
        'Roboto Slab',
        'Rubik',
        'Titillium Web',
        'Ubuntu',
        'Work Sans',
        'Source Sans Pro',
    ],
    // Layout globals
    globalPadding: '2rem',
    globalMargin: '48px auto 48px',
    globalShadow:
        '0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)',

    // Font sizes
    heading: ['52px', '42px', '32px', '24px', '20px', '12px'],
    body: ['16px', '18px'],
    label: {
        small: '12px',
        medium: '14px',
    },
    weight: ['300', '400', '700'],
    align: ['left', 'center', 'right'],
    borderOptions: [
        'none',
        'hidden',
        'dotted',
        'dashed',
        'solid',
        'double',
        'groove',
        'ridge',
        'inset',
        'outset',
    ],
    boxShadowOptions: ['inset ', ' '],
    fontStyleOptions: ['italic', 'oblique', 'normal'],
    fontWeightOptions: [
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
    ],
    transformOptions: ['lowercase', 'full-width', 'uppercase', 'capitalize'],

    pointerOptions: [
        'auto',
        'default',
        'none',
        'context-menu',
        'help',
        'pointer',
        'progress',
        'wait',
        'cell',
        'crosshair',
        'text',
        'vertical-text',
        'alias',
        'copy',
        'move',
        'no-drop',
        'not-allowed',
        'e-resize',
        'n-resize',
        'ne-resize',
        'nw-resize',
        's-resize',
        'se-resize',
        'sw-resize',
        'w-resize',
        'ew-resize',
        'ns-resize',
        'nesw-resize',
        'nwse-resize',
        'col-resize',
        'row-resize',
        'all-scroll',
        'zoom-in',
        'zoom-out',
        'grab',
        'grabbing',
    ],

    // Dynamic Button Up styles
    style: {
        buttonText: 'Button Up!',
        pointer: 'default',
        backgroundColor: '#50E3C2',
        borderColor: '#50E3C2',
        color: '#ffffff',
        borderRadius: '0px',
        padding: '10px',
        borderStyle: '',
        transform: 'unset',
        letterSpacing: '1px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        boxShadow: '0 0 0 0 #ffffff',
        currentShadow: '2px 2px 0 2px #256859',
        fontFamily: 'Open Sans',
        fontSize: '18px',
        hoverBackgroundColor: '',

        hover: {
            backgroundColor: '#ffffff',
            color: '#50E3C2',
            borderColor: '#50E3C2',
        },
    },
};

// Breakpoint sizes
export const size = {
    mobileS: '19rem', // 320px
    mobileM: '26.5625rem', // 425px
    mobileL: '41.875rem', // 670px
    tablet: '50rem', // 769px
    laptop: '64rem', // 1024px
    laptopL: '95rem', // 1440px
    desktop: '160rem', // 2560px
    maxWidth: '78rem',
};

// Media queries
export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};

export const col = {
    auto: '0 0 auto',
    one: '8.33333333%',
    two: '16.66666667%',
    three: '25%',
    four: '33.33333333%',
    five: '41.66666667%',
    six: '50%',
    seven: '58.33333333%',
    eight: '66.66666667%',
    nine: '75%',
    ten: '83.33333333%',
    eleven: '91.66666667%',
    twelve: '100%',
};

export const position = {
    relative: 'relative',
    absolute: 'absolute',
    fixed: 'fixed',
    sticky: 'sticky',
    overflow: 'overflow',
    flex: 'flex',
    grid: 'grid',
    column: 'column',
    row: 'row',
    rowReverse: 'row-reverse',
    columnReverse: 'column-reverse',
    nowrap: 'nowrap',
    wrap: 'wrap',
    wrapeReverse: 'wrap-reverse',
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    baseline: 'baseline',
    between: 'space-between',
    around: 'space-around',
    evenlt: 'space-evenly',
    first: '-1',
    last: '1',
    unset: 'unset',
};

export const box = {
    halfGlobal: '1rem',
    global: '2rem',
    doubleGlobal: '4rem',
    halfGutterWidth: 'calc(1.5rem * 0.5)',
    gutterComp: 'calc((1rem * 0.5) * -1)',
    calculatedPadding: 'calc(3rem + 4vw)',
    halfCalculatedPadding: 'calc(3rem + 2vw)',
    setHeight: '15vh',
};
