import React, { Fragment, useEffect, useRef, useState } from 'react';
import useStagedHistory from './components/custom-hooks/useStagedHistory';
import 'normalize.css';
import { ReactComponent as Arrow } from './components/graphics/arrowup-colour.svg';
import { ThemeProvider } from 'styled-components';
import { col, theme } from './components/globals/theme';
import './index.css';
// Navigation and layout
import Navigation from './components/header/Header.js';
import {
    Header,
    Main,
    ButtonUpContainer,
    Footer,
    FooterContainer,
} from './components/globals/layout';
import {
    CardWrapper,
    ButtonUpFront,
    ButtonUpFace,
    ButtonUpBackColumn,
} from './components/control-panel/style';
import { FlexItem } from 'styled-flex-component';
// Button styles and elements
import ButtonUp from './components/button/ButtonUp';
import {
    ButtonHTML,
    ButtonCSS,
    ButtonCSSHover,
    ButtonUpBack,
    ButtonUpBackContainer,
} from './components/button/ButtonCode';
import RevealSection from './components/button-history/RevealSection';
import {
    HistoryGrid,
    HistoryContainer,
    HistoryButtonItem,
    IconArrow,
} from './components/button-history/style';
// Controls
import TwistButtonWrapper from './components/button/ButtonsTwist';
import { ControlRow } from './components/control-panel/style';
import ControlTextOptions from './components/control-panel/ControlTextOptions';
import {
    getRandomNum,
    invertColor,
    randomItem,
    getRadius,
    getRandomColor,
} from './components/globals/utilities';
import { TabItem, Tabs } from './components/tabs';
import { Column, Row } from './components/globals/grid';
import ControlColors from './components/control-panel/ControlColors';
import ControlAppearance from './components/control-panel/ControlAppearance';
import { useDebouncedCallback } from 'use-debounce/lib';

function App(props) {
    const [style] = useState(theme.style);
    // const [styleHistory, makeStyleHistory] = useState([]);
    const [text, changeText] = useState(theme.style.buttonText);
    const [setActive] = useState(false);
    const [hasShadow, setHasShadow] = useState(false);
    const [activeFontFamily] = useState('Open Sans');
    const [backgroundColor, setBackgroundColor] = useState('#50E3C2');
    const [borderColor, setBorderColor] = useState('#50E3C2');
    const [textColor, setTextColor] = useState('#50E3C2');
    const [isHidden, setIsHidden] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [fontSizeValue, setFontSizeValue] = useState(theme.style.fontSize);

    const {
        state = theme.style,
        setState,
        history,
        commitState,
    } = useStagedHistory();

    // Create a debounced callback allowing us to commit the staged state only
    // after it has been unchaged for one second.
    const autoCommit = useDebouncedCallback(commitState, 500);

    // We don't want to commit the initial state—it should only be committed once
    // the user has changed it. We can achieve this by creating a ref to keep track of
    // whether the user has changed the state.
    //
    // We'll set it to true the first time the state changes.
    const shouldAutoCommit = useRef(false);

    // The effect runs when the component mounts, and when the state changes.
    useEffect(() => {
        // If this effect is called on mount, shouldAutoCommit will be false. So we'll
        // set it to true ready for the next state change.
        if (!shouldAutoCommit.current) {
            shouldAutoCommit.current = true;
            return;
        }

        autoCommit.callback();
    }, [autoCommit, state]);

    // const reset = () => {
    //     shouldAutoCommit.current = false;
    //     setState(state);
    // };

    // Update the style object in /global/.theme.js

    const handleUpdateStyle = (newStyle) => {
        shouldAutoCommit.current = false;
        setState(() => ({ ...state, ...newStyle }));
    };

    const putBackStyle = (oldStyle) => {
        if (!shouldAutoCommit.current) {
            shouldAutoCommit.current = true;
            return;
        }
        shouldAutoCommit.current = false;
        setState(() => ({ ...state, ...oldStyle }));
    };

    //const handleUpdateStyle = (newStyle) => newStyle;

    // getter for style object
    const getStyle = (key) => {
        return style[key];
    };

    // Generate random values for each of the styles stored in theme
    const generateRandom = (miniStyle) => {
        miniStyle = {};
        miniStyle.hover = {};

        miniStyle.pointer = randomItem(theme.pointerOptions);
        miniStyle.backgroundColor = getRandomColor();
        // Generates an inverted color from backgroundColor
        miniStyle.borderColor = invertColor(miniStyle.backgroundColor);
        miniStyle.color = invertColor(miniStyle.backgroundColor);
        miniStyle.borderRadius = getRadius() + `px`;
        miniStyle.padding =
            getRandomNum(50, 5) + `px ` + getRandomNum(28, 5) + `px`;
        miniStyle.borderStyle = randomItem(theme.borderOptions);
        miniStyle.borderWidth = getRandomNum(8, 0) + `px`;
        miniStyle.transform = randomItem(theme.transformOptions);
        miniStyle.fontWeight = randomItem(theme.fontWeightOptions);
        miniStyle.fontFamily = randomItem(theme.fontFamilies);
        miniStyle.fontSize = getRandomNum(25, 10) + `px`;
        miniStyle.letterSpacing = getRandomNum(12, -2) + `px`;
        miniStyle.fontStyle = randomItem(theme.fontStyleOptions);
        // Hover style
        miniStyle.hover = {
            backgroundColor: invertColor(miniStyle.backgroundColor),
            color: getRandomColor(),
            borderColor: invertColor(miniStyle.backgroundColor),
        };
        // Hovered styles
        if (hasShadow) {
            // Horizontal + Verticle + Blur (breaks with negative values) + Spread + colour
            miniStyle.boxShadow =
                randomItem(theme.boxShadowOptions) +
                getRandomNum(40, -20) +
                `px ` +
                getRandomNum(40, -20) +
                `px ` +
                getRandomNum(40, 0) +
                `px ` +
                getRandomNum(40, -20) +
                `px ` +
                getRandomColor();
        }

        //console.log(miniStyle);
        setState(() => miniStyle);
    };

    // Handles the onchange of the text input for the button
    const handleTextInput = (e) => {
        const textInput = e.target.value;
        // this toggles the state on the input to not active
        changeText(textInput);
        setActive(false);
        //console.log("current text is " + text);
    };

    const changeFontSize = (value) => {
        handleUpdateStyle({ fontSize: value + `px` });
        console.log(value);
    };

    const changeFontWeight = (value) => {
        handleUpdateStyle({ fontWeight: value });
        //console.log(value);
    };

    const changeBorderStyle = (value) => {
        handleUpdateStyle({ borderStyle: value });
        //console.log(value);
    };

    const minusFontSize = (fontSizeValue) => {
        setFontSizeValue((fontSizeValue = style.fontSize));
        //console.log(fontSizeValue);
        let decrementFontSizeValue = parseInt(fontSizeValue, 10) - 1;
        //console.log(decrementFontSizeValue);
        handleUpdateStyle({ fontSize: decrementFontSizeValue + `px` });
        //console.log(fontSizeValue);
    };

    const plusFontSize = (fontSizeValue) => {
        setFontSizeValue((fontSizeValue = style.fontSize));
        //console.log(fontSizeValue);
        let decrementFontSizeValue = parseInt(fontSizeValue, 10) + 1;
        //console.log(decrementFontSizeValue);
        handleUpdateStyle({ fontSize: decrementFontSizeValue + `px` });
        //console.log(fontSizeValue);
    };

    // Could add in select bewteen px and %
    const changeBorderRadius = (value) => {
        handleUpdateStyle({ borderRadius: value + `px` });
        //console.log(value)
    };

    const changeBackgroundColor = (color) => {
        // Updates the style object with the picked colour
        handleUpdateStyle({ backgroundColor: color.hex });
        setBackgroundColor(color.hex);
        //console.log(backgroundColor);
    };

    // Function to change the border colour
    const changeBorderColour = (color) => {
        // Updates the style object with the picked colour
        handleUpdateStyle({ borderColor: color.hex });
        setBorderColor(color.hex);
    };

    const changeTextColour = (color) => {
        // Updates the style object with the picked colour
        handleUpdateStyle({ color: color.hex });
        setTextColor(color.hex);
    };

    // if clicked the shadow is removed
    // store the removed shadow as currentShadow
    // when clicked again add the stored currentShadow back

    //console.log(state);
    // console.log(history);
    const toggleBoxShadow = () => {
        const shadowString = getStyle('currentShadow');
        const currentShadow = history[0].boxShadow;

        if (hasShadow) {
            console.log('removing box shadow ' + getStyle('boxShadow'));
            handleUpdateStyle({
                boxShadow: 'none',
                currentShadow: shadowString,
            });
            setHasShadow(false);
        } else if (!hasShadow) {
            console.log('add current shadow ' + currentShadow);
            handleUpdateStyle({ boxShadow: currentShadow });
            setHasShadow(true);
        }
    };

    // Check if there is a style history
    const hasStyleHistory = history.length === 0;

    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Header>
                    <Navigation />
                </Header>
                <Main>
                    <Row alignItems="center" justifyContent="center">
                        <Column
                            xsWidth={col.twelve}
                            mdWidth={col.eight}
                            lgWidth={col.ten}
                        >
                            <ButtonUpContainer isFlipped={isFlipped}>
                                <TwistButtonWrapper
                                    onClick={() => generateRandom()}
                                    isFlipped={isFlipped}
                                />
                                <ButtonUpFace isFlipped={isFlipped}>
                                    <ButtonUpFront>
                                        <ButtonUp
                                            activeFontFamily={activeFontFamily}
                                            className="apply-font"
                                            fontSize={props.valueNow}
                                            buttonText={text}
                                            state={state}
                                        />
                                    </ButtonUpFront>

                                    <ButtonUpBack>
                                        <ButtonUpBackContainer>
                                            <Row
                                                width="100%"
                                                justifyContent="space-between"
                                            >
                                                <Column>
                                                    <ButtonHTML text={text} />
                                                </Column>
                                                <Column>
                                                    <ButtonCSS style={state} />
                                                </Column>
                                                <Column>
                                                    <ButtonCSSHover
                                                        style={state}
                                                    />
                                                </Column>
                                            </Row>
                                            <ButtonUpBackColumn></ButtonUpBackColumn>
                                            <FlexItem column></FlexItem>
                                        </ButtonUpBackContainer>
                                    </ButtonUpBack>
                                </ButtonUpFace>
                            </ButtonUpContainer>
                            <RevealSection
                                setIsFlipped={setIsFlipped}
                                isFlipped={isFlipped}
                                setIsHidden={setIsHidden}
                                isHidden={isHidden}
                            />
                        </Column>
                    </Row>
                    <Row alignItems="center" justifyContent="center">
                        <Column
                            xsWidth={col.twelve}
                            mdWidth={col.eight}
                            lgWidth={col.ten}
                        >
                            <HistoryContainer
                                className="full hide-scroll"
                                isHidden={isHidden}
                            >
                                <HistoryGrid className="history-grid">
                                    {!hasStyleHistory &&
                                        history
                                            .reverse()
                                            .map((state, index) => (
                                                <HistoryButtonItem
                                                    key={index}
                                                    className="item"
                                                >
                                                    <IconArrow>
                                                        <Arrow
                                                            onClick={() =>
                                                                putBackStyle(
                                                                    state
                                                                )
                                                            }
                                                        />
                                                    </IconArrow>
                                                    <ButtonUp
                                                        state={state}
                                                        buttonText={text}
                                                        fontSize={
                                                            props.fontSize
                                                        }
                                                        className="apply-font"
                                                        activeFontFamily={
                                                            props.activeFontFamily
                                                        }
                                                    />
                                                </HistoryButtonItem>
                                            ))}
                                </HistoryGrid>
                            </HistoryContainer>
                        </Column>
                    </Row>
                    <Row alignItems="center" justifyContent="center">
                        <Column
                            xsWidth={col.twelve}
                            mdWidth={col.eight}
                            lgWidth={col.ten}
                        >
                            <CardWrapper>
                                <Tabs defaultIndex="1" onTabClick={console.log}>
                                    <TabItem label="Font" index="1">
                                        <ControlRow>
                                            <ControlTextOptions
                                                text={text}
                                                state={state}
                                                handleTextInput={
                                                    handleTextInput
                                                }
                                                // Font Picker props
                                                activeFontFamily={
                                                    activeFontFamily
                                                }
                                                handleUpdateStyle={
                                                    handleUpdateStyle
                                                }
                                                apiKey={props.apiKey}
                                                // Font Size
                                                defaultValue={
                                                    props.defaultValue
                                                }
                                                max={props.max}
                                                changeFontSize={changeFontSize}
                                                fontSize={props.fontSize}
                                                minusFontSize={minusFontSize}
                                                fontSizeValue={fontSizeValue}
                                                plusFontSize={plusFontSize}
                                                // Font Weight
                                                changeFontWeight={
                                                    changeFontWeight
                                                }
                                            />
                                        </ControlRow>
                                    </TabItem>
                                    <TabItem label="Colours" index="2">
                                        <ControlRow>
                                            <ControlColors
                                                changeBorderRadius={
                                                    changeBorderRadius
                                                }
                                                changeBorderColour={
                                                    changeBorderColour
                                                }
                                                borderColor={borderColor}
                                                changeBackgroundColor={
                                                    changeBackgroundColor
                                                }
                                                backgroundColor={
                                                    backgroundColor
                                                }
                                                changeTextColour={
                                                    changeTextColour
                                                }
                                                textColor={textColor}
                                            />
                                        </ControlRow>
                                    </TabItem>
                                    <TabItem label="Appearance" index="3">
                                        <ControlRow>
                                            <ControlAppearance
                                                shadow={state.currentShadow}
                                                toggleBoxShadow={
                                                    toggleBoxShadow
                                                }
                                                state={state}
                                                changeBorderStyle={
                                                    changeBorderStyle
                                                }
                                            />
                                        </ControlRow>
                                    </TabItem>
                                </Tabs>
                            </CardWrapper>
                        </Column>
                    </Row>
                    <Footer>
                        <FooterContainer></FooterContainer>
                    </Footer>
                </Main>
            </Fragment>
        </ThemeProvider>
    );
}

export default App;
