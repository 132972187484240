// Generates a random number with two arguments to define the value range
export function getRandomNum(n, m) {
  //console.log('Shared Random Number made')
  return Math.floor(Math.random() * n) + m;
}

export function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

// Use this to return a random string from object/array
export const randomItem = (item) =>
  item[Math.floor(Math.random() * item.length)];

export const getRadius = () =>
  (getRadius.number = Math.floor(Math.random() * (35 + 5))) ===
  getRadius.lastNumber
    ? getRadius()
    : (getRadius.lastNumber = getRadius.number);

// Generate a random hex value
export function getRandomColor() {
  var length = 6;
  var chars = "0123456789ABCDEF";
  var hex = "#";
  while (length--) hex += chars[(Math.random() * 16) | 0];
  return hex;
}
