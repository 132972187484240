import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../globals/theme";
import styled from "styled-components";

// Used in ControlPanel.js with state
export const InputContainer = styled.div`
  position: relative;
  width: 125px;
`;

// Used in ControlPanel.js with state
export const InputField = styled.input`
  display: block;
  line-height: 1.5em;
  border: none;
  height: 31px;
  width: 100px;
  border-bottom: 2px solid ${(props) => props.theme.color.gray};
  background-color: transparent;
  border-color: ${(props) => props.theme.color.gray};
  color: ${(props) => props.theme.color.gray};
  font-size: ${(props) => props.theme.label.medium};
  font-weight: ${(props) => props.theme.weight[1]};

  &:hover {
    border-color: ${(props) => props.theme.color.brand};
  }

  &::placeholder {
    letter-spacing: 1px;
    font-size: 15px;
    color: ${(props) => props.theme.color.gray};
    font-weight: 700;
    text-align: center;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 5px;
  right: 0;
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 999999;
`;

const CheckmarkCircle = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: ${(props) =>
    props.active ? props.theme.color.brand : props.theme.color.gray};
  border-radius: 11px;
  left: 0;
  top: 0;
`;

const CheckmarkStem = styled.div`
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
`;

const CheckmarkKick = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
`;

export const InputCheckMark = (props) => {
  //console.log(props)
  return (
    <ThemeProvider theme={theme}>
      <Checkmark onClick={props.handleUpdateText}>
        <CheckmarkCircle active={props.active} />
        <CheckmarkStem />
        <CheckmarkKick />
      </Checkmark>
    </ThemeProvider>
  );
};

export default InputCheckMark;
